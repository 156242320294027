import { Row } from 'react-bootstrap';
import SubscribeSection from '../components/subscribe/SubscribeSection';
function Subscribe() {
    return (
        <div>
            <Row className="row-center justify-content-center">
                <SubscribeSection />
            </Row>
        </div>
    )
}

export default Subscribe