import React from "react";

const SubscribeForm = () => {
    return (
        <div id="mc_embed_shell" className="formSize">
            {/* Mailchimp Embed Styles */}
            <link
                href="//cdn-images.mailchimp.com/embedcode/classic-061523.css"
                rel="stylesheet"
                type="text/css"
            />
            <style>
                {`
          #mc_embed_signup {
            background: #fff;
            clear: left;
            font: 14px Helvetica, Arial, sans-serif;
            width: 600px;
          }
        `}
            </style>

            <div id="mc_embed_signup">
                <form
                    action="https://ej-software.us12.list-manage.com/subscribe/post?u=addb56e4bd200c0c3c8904783&amp;id=09cea7cdd5&amp;f_id=00fdb0e0f0"
                    method="post"
                    id="mc-embedded-subscribe-form"
                    name="mc-embedded-subscribe-form"
                    className="validate"
                    target="_blank"
                    noValidate
                >
                    <div id="mc_embed_signup_scroll">
                        <div className="indicates-required">
                            <span className="asterisk">*</span> indicates required
                        </div>
                        <div className="mc-field-group">
                            <label htmlFor="mce-EMAIL">
                                Email Address <span className="asterisk">*</span>
                            </label>
                            <input
                                type="email"
                                name="EMAIL"
                                className="required email"
                                id="mce-EMAIL"
                                required
                            />
                        </div>
                        <div className="mc-field-group">
                            <label htmlFor="mce-FNAME">
                                First Name <span className="asterisk">*</span>
                            </label>
                            <input
                                type="text"
                                name="FNAME"
                                className="required text"
                                id="mce-FNAME"
                                required
                            />
                        </div>
                        <div className="mc-field-group">
                            <label htmlFor="mce-LNAME">
                                Last Name <span className="asterisk">*</span>
                            </label>
                            <input
                                type="text"
                                name="LNAME"
                                className="required text"
                                id="mce-LNAME"
                                required
                            />
                        </div>
                        <div className="mc-field-group">
                            <label htmlFor="mce-PHONE">Phone Number</label>
                            <input
                                type="text"
                                name="PHONE"
                                className="REQ_CSS"
                                id="mce-PHONE"
                            />
                        </div>
                        <div className="mc-field-group">
                            <label htmlFor="mce-COMPANY">Company</label>
                            <input
                                type="text"
                                name="COMPANY"
                                className="text"
                                id="mce-COMPANY"
                            />
                        </div>
                        <div id="mce-responses" className="clear">
                            <div
                                className="response"
                                id="mce-error-response"
                                style={{ display: "none" }}
                            ></div>
                            <div
                                className="response"
                                id="mce-success-response"
                                style={{ display: "none" }}
                            ></div>
                        </div>
                        <div
                            aria-hidden="true"
                            style={{ position: "absolute", left: "-5000px" }}
                        >
                            <input
                                type="text"
                                name="b_addb56e4bd200c0c3c8904783_09cea7cdd5"
                                tabIndex="-1"
                                value=""
                            />
                        </div>
                        <div className="clear">
                            <input
                                type="submit"
                                name="subscribe"
                                id="mc-embedded-subscribe"
                                className="button ejButton"
                                value="Subscribe"
                            />
                        </div>
                    </div>
                </form>
            </div>

            {/* External Mailchimp Validation Script */}
            <script
                type="text/javascript"
                src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"
            ></script>
            <script
                dangerouslySetInnerHTML={{
                    __html: `
            (function($) {
              window.fnames = new Array();
              window.ftypes = new Array();
              fnames[0] = 'EMAIL';
              ftypes[0] = 'email';
              fnames[1] = 'FNAME';
              ftypes[1] = 'text';
              fnames[2] = 'LNAME';
              ftypes[2] = 'text';
              fnames[4] = 'PHONE';
              ftypes[4] = 'phone';
              fnames[6] = 'COMPANY';
              ftypes[6] = 'text';
              fnames[3] = 'ADDRESS';
              ftypes[3] = 'address';
              fnames[5] = 'BIRTHDAY';
              ftypes[5] = 'birthday';
            }(jQuery));
            var $mcj = jQuery.noConflict(true);
          `,
                }}
            />
        </div>
    );
};

export default SubscribeForm;
