import "../../assets/css/subscribe.scss"
import { Row, Col, Image } from "react-bootstrap"
import SubscribeForm from "./SubscribeForm"
import daBoyz from '../../assets/images/daBoyz.jpg';

const SubscribeSection = () => {
    return (
        <div className="subscribeContent">
            <Row>
                <Col className="subscribeTextContainer" xs="12" sm="12" md="6" lg="6">
                    <div className="subscribeText">
                        <h2 className="subscribeBold alignLeft">Stay Connected, Stay Ahead</h2>
                        <p className="alignLeft">Subscribe to our monthly newsletter and get exclusive insights straight to your inbox.</p>
                        <h2 className="subscribeBold alignLeft">What You'll Get:</h2>
                        <ul className="subscribeList alignLeft">
                            <li><span className="subscribeBold">Team Updates:</span> See what's happening behind the scenes at EJ Software.</li>
                            <li><span className="subscribeBold">Tech Trends:</span> Stay on top of cutting-edge innovations and industry insights.</li>
                            <li><span className="subscribeBold">Expert Tips:</span> Practical advice to help your business thrive in the digital world.</li>
                            <li><span className="subscribeBold">Much More!</span></li>
                        </ul>
                    </div>
                </Col>
                <Col>
                    <SubscribeForm />
                </Col>
            </Row>
        </div>
    )
}

export default SubscribeSection